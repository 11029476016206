var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "route-list route-details collection-details" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-modal",
            {
              ref: "modal",
              attrs: {
                id: "modal",
                title: _vm.modalTitle,
                "header-class": "justify-content-center",
                centered: "",
                "hide-header-close": "",
                "hide-footer": "",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c("div", { staticClass: "d-block text-center" }, [
                _c("div", { staticClass: "modalText" }, [
                  _vm._v("What do you want to do next?"),
                ]),
                _c("div", { staticClass: "modalItems" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          class: !_vm.$store.getters.isOnline ? "disabled" : "",
                          attrs: {
                            disabled: !_vm.$store.getters.isOnline,
                            event: _vm.$store.getters.isOnline ? "click" : "",
                            to: {
                              name: "route-details",
                              params: { routeId: _vm.$route.params.routeId },
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            staticStyle: { width: "30px", height: "30px" },
                            attrs: { icon: "arrow-left", variant: "primary" },
                          }),
                          _c("br"),
                          _vm._v(" Back to route details "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.nextStop !== null
                    ? _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              class: !_vm.$store.getters.isOnline
                                ? "disabled"
                                : "",
                              attrs: {
                                disabled: !_vm.$store.getters.isOnline,
                                event: _vm.$store.getters.isOnline
                                  ? "click"
                                  : "",
                                to: {
                                  name: "route-item-details",
                                  params: {
                                    routeId: _vm.$route.params.routeId,
                                    itemId: _vm.nextStop.id,
                                  },
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                staticStyle: { width: "30px", height: "30px" },
                                attrs: {
                                  icon: "house-door",
                                  variant: "primary",
                                },
                              }),
                              _c("br"),
                              _vm._v(" Go to next stop"),
                              _c("br"),
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.nextStop.address1) +
                                    " " +
                                    _vm._s(_vm.nextStop.address2)
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "pb-1", attrs: { cols: "12" } },
                [
                  _c(
                    "router-link",
                    {
                      class: !_vm.$store.getters.isOnline ? "disabled" : "",
                      attrs: {
                        disabled: !_vm.$store.getters.isOnline,
                        event: _vm.$store.getters.isOnline ? "click" : "",
                        to: {
                          name: "route-details",
                          params: { routeId: _vm.$route.params.routeId },
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: { icon: "arrow-left", variant: "primary" },
                      }),
                      _vm._v(" Back to route details "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-2" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "route-map",
                    { attrs: { coordinates: _vm.model.coordinates, zoom: 15 } },
                    [
                      _c("div", { staticClass: "info-window" }, [
                        _c(
                          "p",
                          {
                            staticClass: "text-center",
                            class: _vm.statusBarStyle,
                          },
                          [
                            _vm.model.status === _vm.stopStatuses.collected
                              ? _c("b-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                  },
                                  attrs: {
                                    icon: "check2-square",
                                    variant: "secondary",
                                  },
                                })
                              : _vm._e(),
                            _vm.model.status ===
                            _vm.stopStatuses.requiresAttention
                              ? _c("b-icon", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                  },
                                  attrs: {
                                    icon: "exclamation-square",
                                    variant: "danger",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("p", [
                          _c("span", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.model.firstName) +
                                  " " +
                                  _vm._s(_vm.model.lastName)
                              ),
                            ]),
                          ]),
                        ]),
                        _c("p", [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.model.address1) +
                                " " +
                                _vm._s(_vm.model.address2) +
                                " " +
                                _vm._s(_vm.model.city) +
                                " " +
                                _vm._s(_vm.model.postcode)
                            ),
                          ]),
                        ]),
                        _c("p", [
                          _c("span", [_vm._v("Number of trees: ")]),
                          _vm._v(_vm._s(_vm.model.numberOfTrees)),
                        ]),
                        _c("p", [
                          _c("small", [_vm._v(_vm._s(_vm.model.instructions))]),
                        ]),
                        _c("p", [
                          _c("span", [_vm._v("What3Words: ")]),
                          _vm._v(_vm._s(_vm.model.what3Words)),
                        ]),
                        _c(
                          "p",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-100",
                                attrs: {
                                  variant: "outline-success",
                                  href: `tel:${_vm.model.phoneNumber}`,
                                },
                              },
                              [_vm._v(_vm._s(_vm.model.phoneNumber) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-100 color--white",
                                attrs: {
                                  href: _vm.navigationLink,
                                  target: "_blank",
                                  type: "button",
                                  variant: "success",
                                },
                              },
                              [_vm._v(" Navigate ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-2" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "textarea-rows",
                              placeholder: "Notes ...",
                              rows: "4",
                              state: _vm.validationState(
                                _vm.$v.model.collectorNote
                              ),
                            },
                            model: {
                              value: _vm.$v.model.collectorNote.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.model.collectorNote,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.model.collectorNote.$model",
                            },
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              attrs: {
                                state: _vm.validationState(
                                  _vm.$v.model.collectorNote
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "This field is required and must be between 5 and 150 characters length "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4", attrs: { cols: "12" } },
                    [
                      !_vm.model.showChangeAsNoCollected
                        ? _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "button--large color--white",
                                  attrs: {
                                    disabled: !_vm.$store.getters.isOnline,
                                    type: "button",
                                    variant: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(
                                        "completed",
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Collected ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.model.showChangeAsNoCollected
                        ? _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "button--large color--white float-right",
                                  attrs: {
                                    disabled: !_vm.$store.getters.isOnline,
                                    type: "button",
                                    variant: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus("missing", true)
                                    },
                                  },
                                },
                                [_vm._v(" Missing ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.showChangeAsNoCollected
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "button--large color--white",
                                  attrs: {
                                    disabled: !_vm.$store.getters.isOnline,
                                    type: "button",
                                    variant: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(
                                        "readytocollect",
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Mark as ready to collect ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-alert",
            {
              staticClass: "position-fixed fixed-top m-0 rounded-0",
              staticStyle: { "z-index": "2000", "text-align": "center" },
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.hasError,
                callback: function ($$v) {
                  _vm.hasError = $$v
                },
                expression: "hasError",
              },
            },
            [_vm._v(" An error has occurred. ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }