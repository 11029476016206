<template>
  <div class="route-map">
    <GmapMap
        :center="coordinates"
        :zoom="zoom"
        map-type-id="roadmap"
        style="width: 100%; height: 600px"
    >
      <gmap-info-window
          :options="infoOptions"
          :position="coordinates"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen=false"
      >
        <slot></slot>
      </gmap-info-window>
      <GmapMarker
          :position="coordinates"
          :clickable="true"
          :draggable="true"
          @click="openInfoWindow()"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "RouteMap",
  data: function () {
    return {
      infoWinOpen: true,
      currentMidx: null,
      fullscreenControl: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    }
  },
  props: {
    coordinates: {
      type: Object,
      default: function () {
        return {
          lat: 51,
          lng: 0,
        }
      }
    },
    zoom: {
      type: Number,
      default: 12
    }
  },
  methods: {
    openInfoWindow: function () {
      this.infoWinOpen = true;
    }
  }
}
</script>
<style lang="scss">
.vue-map-container {
  margin-left: auto;
  margin-right: auto;
}
</style>