var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-page d-flex align-items-center" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _vm.hospiceList.length > 0
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "12" } },
                        [
                          _vm._v(" Select a charity area: "),
                          _c("b-form-select", {
                            attrs: {
                              name: "routeId",
                              options: _vm.hospiceList,
                              disabled:
                                _vm.routeOptions.length > 1 ||
                                _vm.isCollectionKeySetInUrl,
                            },
                            model: {
                              value: _vm.state.uniqueKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.state, "uniqueKey", $$v)
                              },
                              expression: "state.uniqueKey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.state.uniqueKey !== null
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "12" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              name: "email",
                              placeholder: "Email *",
                              disabled: _vm.routeOptions.length > 1,
                            },
                            model: {
                              value: _vm.$v.state.email.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.state.email, "$model", $$v)
                              },
                              expression: "$v.state.email.$model",
                            },
                          }),
                          _vm.$v.state.email.$dirty &&
                          !_vm.$v.state.email.required
                            ? _c("b-form-invalid-feedback", [
                                _vm._v("Field is required "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "12" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              name: "nickname",
                              placeholder: "User *",
                              disabled: _vm.routeOptions.length > 1,
                            },
                            model: {
                              value: _vm.$v.state.nickname.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.state.nickname, "$model", $$v)
                              },
                              expression: "$v.state.nickname.$model",
                            },
                          }),
                          _vm.$v.state.nickname.$dirty &&
                          !_vm.$v.state.nickname.required
                            ? _c("b-form-invalid-feedback", [
                                _vm._v("Field is required "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "12" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              name: "password",
                              type: "password",
                              placeholder: "Password *",
                              disabled: _vm.routeOptions.length > 1,
                            },
                            model: {
                              value: _vm.$v.state.password.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.state.password, "$model", $$v)
                              },
                              expression: "$v.state.password.$model",
                            },
                          }),
                          _vm.$v.state.password.$dirty &&
                          !_vm.$v.state.password.required
                            ? _c("b-form-invalid-feedback", [
                                _vm._v("Field is required "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.routeOptions.length === 1
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "button--large color--white",
                                  attrs: { type: "submit", variant: "primary" },
                                },
                                [_vm._v("Login ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.routeOptions.length > 1
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pb-3", attrs: { cols: "12" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              name: "routeId",
                              options: _vm.routeOptions,
                              disabled: _vm.state.routeId === null,
                            },
                            model: {
                              value: _vm.state.routeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.state, "routeId", $$v)
                              },
                              expression: "state.routeId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "button--large color--white",
                              attrs: { variant: "primary" },
                              on: { click: _vm.submitLogin },
                            },
                            [_vm._v("Next ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-alert",
            {
              staticClass: "position-fixed fixed-top m-0 rounded-0",
              staticStyle: { "z-index": "2000", "text-align": "center" },
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.hasValidationError,
                callback: function ($$v) {
                  _vm.hasValidationError = $$v
                },
                expression: "hasValidationError",
              },
            },
            [_vm._v(" Please provide correct data, ")]
          ),
          _c(
            "b-alert",
            {
              staticClass: "position-fixed fixed-top m-0 rounded-0",
              staticStyle: { "z-index": "2000", "text-align": "center" },
              attrs: { variant: "warning", dismissible: "" },
              model: {
                value: _vm.emailNotRegistered,
                callback: function ($$v) {
                  _vm.emailNotRegistered = $$v
                },
                expression: "emailNotRegistered",
              },
            },
            [
              _vm._v(" Email not registered please follow this "),
              _c("a", { attrs: { href: _vm.getLink() } }, [_vm._v(" link")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }