var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobile-app", attrs: { id: "app" } },
    [
      _c("busy-indicator"),
      _c(
        "div",
        { staticClass: "mobile-app__header" },
        [
          _vm.$store.getters.isOnline
            ? _c("div", { staticClass: "divider background--pepper" })
            : _vm._e(),
          !_vm.$store.getters.isOnline
            ? _c("div", { staticClass: "divider background--error" }, [
                _c("p", [_vm._v("You are offline")]),
              ])
            : _vm._e(),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "header-content color--white" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center pt-3", attrs: { cols: "12" } },
                    [
                      _c("b-img", {
                        staticClass: "header-content__logo",
                        attrs: { src: require("./assets/logo.png") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.$store.getters.isAuthenticated
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "text-center pt-3",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "b-link",
                            {
                              attrs: {
                                href: "https://www.youtube.com/watch?v=wAjeGbsBbfw",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " Welcome to Just Helping Christmas Tree Recycling App 'click on the link to view the tutorial' "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "mobile-app__content" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }