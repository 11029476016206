import { AUTH_COMMIT, AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS, UNLOCK_ROUTE } from "./constants";

import axios from "axios";

const state = {
    code: sessionStorage.getItem("user-code") || "",
    reservedRoute: sessionStorage.getItem("user-route") || "",
    status: "",
    hasLoadedOnce: false
};

const getters = {
    isAuthenticated: state => !!state.code,
    hasReservedRoute: state => !!state.reservedRoute,
    reservedRoute: state => atob(state.reservedRoute),
    authStatus: state => state.status
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios({
                method: "POST",
                url: process.env.VUE_APP_LOGIN_URL,
                data: {
                    uniqueKey: user.state.uniqueKey,
                    nickname: user.state.nickname,
                    password: user.state.password,
                    email: user.state.email
                }
            }).then(resp => {
                resolve(resp);
            }).catch(err => {
                commit(AUTH_ERROR, err);
                sessionStorage.removeItem("user-code");
                sessionStorage.removeItem("user-route");
                reject(err);
            });
        });
    },
    [AUTH_COMMIT]: ({ commit }, data) => {
        return new Promise(resolve => {
            sessionStorage.setItem("user-code", data.authData);
            sessionStorage.setItem("user-route", data.routeId);
            
            commit(AUTH_SUCCESS, data);
            resolve();
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            sessionStorage.removeItem("user-code");
            sessionStorage.removeItem("user-route");
            resolve();
        });
    },
    [UNLOCK_ROUTE]: ({ commit }) => {
        return new Promise(resolve => {
            commit(UNLOCK_ROUTE);
            sessionStorage.removeItem("user-route");
            resolve();
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.code = resp.authData;
        state.reservedRoute = resp.routeId;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.code = "";
        state.reservedRoute = "";
    },
    [UNLOCK_ROUTE]: state => {
        state.reservedRoute = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};