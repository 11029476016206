import './registerServiceWorker'
import './main.scss'

import * as VueGoogleMaps from 'vue2-google-maps'

import {AUTH_LOGOUT} from "./stores/modules/constants";
import App from './App.vue'
import JhBootstrapComponents from "./plugins/JhBootstrapComponents"
import Vue from 'vue'
import Vuelidate from "vuelidate/src";
import axios from 'axios'
import busyIndicatorPlugin from "./plugins/BusyIndicator"
import router from './router'
import store from './stores'

Vue.config.productionTip = false

Vue.use(JhBootstrapComponents);
Vue.use(VueGoogleMaps);
Vue.use(busyIndicatorPlugin, {className: "busy"});
Vue.use(Vuelidate);

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (config) {
    let userCode = sessionStorage.getItem('user-code');

    if (userCode) {
        config.headers["Access-Code"] = userCode;
    }

    return config;
}, function (error) {
    if (error.response.status === 401) {
        router.push({name: "login"});
    }
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        store.commit(AUTH_LOGOUT);
        sessionStorage.removeItem("user-code");
        sessionStorage.removeItem("user-route");
        this.$setBusy(true);
        router.push({name: "login"});
    } else {
        return Promise.reject(error);
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
