<template>
    <div class="busy-indicator">
        <b-spinner variant="primary"></b-spinner>
    </div>
</template>

<script>
    import {BSpinner} from "bootstrap-vue";

    export default {
        name: "busyIndicator",
        components: {BSpinner},
        mounted() {
        },
        data() {
            return {};
        },
        computed: {},
        methods: {}
    };
</script>
<style lang="scss">
    @import "../scss/variables";

    body {
        .busy-indicator {
            display: none;
        }

        &.busy {
            .busy-indicator {
                position: fixed;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                background-color: $darken-gray;
                opacity: 0.9;
                z-index: 1001;

                .spinner-border {
                    width: 4rem;
                    height: 4rem;
                    opacity: 1;
                }
            }
        }
    }
</style>