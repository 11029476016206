import { APP_CHECK_INTERNET_CONNECTION, APP_INTERNET_CONNECTION_FAILED, APP_INTERNET_CONNECTION_SUCCESS } from "./constants";

import axios from "axios";

const state = {
    isOnline: true
};

const getters = {
    isOnline: state => state.isOnline,
};

const actions = {
    [APP_CHECK_INTERNET_CONNECTION]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(APP_CHECK_INTERNET_CONNECTION);
            axios({
                method: "GET",
                url: process.env.VUE_APP_BASE_URL + "/ping"
            }).then(() => {
                commit(APP_INTERNET_CONNECTION_SUCCESS);
                resolve();
            }).catch(() => {
                commit(APP_INTERNET_CONNECTION_FAILED);
                resolve();
            });
        });
    },
};

const mutations = {
    [APP_CHECK_INTERNET_CONNECTION]: () => {
        //state.isOnline = false;
    },
    [APP_INTERNET_CONNECTION_SUCCESS]: state => {
        state.isOnline = true;
    },
    [APP_INTERNET_CONNECTION_FAILED]: state => {
        state.isOnline = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};