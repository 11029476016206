export default {
    install: function (Vue, options) {
        Vue.prototype.$setBusy = function (option) {
            if (option) {
                document.body.classList.add(options.className);
            } else {
                document.body.classList.remove(options.className);

            }
        };
    }
}