<template>
  <div class="login-page d-flex align-items-center">
    <b-container>
      <b-form @submit.stop.prevent="submit">
        <b-row v-if="hospiceList.length > 0">
          <b-col cols="12" class="pb-3">
            Select a charity area:
            <b-form-select
              name="routeId"
              :options="hospiceList"
              v-model="state.uniqueKey"
              :disabled="routeOptions.length > 1 || isCollectionKeySetInUrl"
            ></b-form-select>
          </b-col>
        </b-row>

        <b-row v-if="state.uniqueKey !== null">
          <b-col cols="12" class="pb-3">
            <b-form-input
              name="email"
              v-model="$v.state.email.$model"
              :placeholder="'Email *'"
              :disabled="routeOptions.length > 1"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="$v.state.email.$dirty && !$v.state.email.required"
              >Field is required
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" class="pb-3">
            <b-form-input
              name="nickname"
              v-model="$v.state.nickname.$model"
              :placeholder="'User *'"
              :disabled="routeOptions.length > 1"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="$v.state.nickname.$dirty && !$v.state.nickname.required"
              >Field is required
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" class="pb-3">
            <b-form-input
              name="password"
              type="password"
              v-model="$v.state.password.$model"
              :placeholder="'Password *'"
              :disabled="routeOptions.length > 1"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="$v.state.password.$dirty && !$v.state.password.required"
              >Field is required
            </b-form-invalid-feedback>
          </b-col>
          <b-col v-if="routeOptions.length === 1" cols="12" class="text-center">
            <b-button
              type="submit"
              variant="primary"
              class="button--large color--white"
              >Login
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="routeOptions.length > 1">
          <b-col cols="12" class="pb-3">
            <b-form-select
              name="routeId"
              :options="routeOptions"
              v-model="state.routeId"
              :disabled="state.routeId === null"
            ></b-form-select>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              v-on:click="submitLogin"
              variant="primary"
              class="button--large color--white"
              >Next
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-alert
        v-model="hasValidationError"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000; text-align: center"
        variant="danger"
        dismissible
      >
        Please provide correct data,
      </b-alert>
      <b-alert
        v-model="emailNotRegistered"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000; text-align: center"
        variant="warning"
        dismissible
      >
      Email not registered please follow this <a :href="getLink()"> link</a>
      </b-alert>
    </b-container>
  </div>
</template>

<script>
import { AUTH_COMMIT, AUTH_REQUEST } from "../stores/modules/constants";
import { required } from "vuelidate/lib/validators";
import ValidationMixins from "../mixins/validationMixins";
import axios from "axios";

export default {
  name: "Login",
  mixins: [ValidationMixins],
  data() {
    return {
      hasValidationError: false,
      emailNotRegistered: false,
      isCollectionKeySetInUrl: false,
      hospiceList: [],
      state: {
        uniqueKey: null,
        nickname: null,
        email: null,
        password: null,
        routeId: null,
      },
      routeOptions: [
        { value: null, text: "Please select a route", disabled: true },
      ],
      authData: null,
    };
  },
  validations: {
    state: {
      uniqueKey: { required },
      nickname: { required },
      email: { required },
      password: { required },
    },
  },
  created() {
    var collectionKey = this.$route.params.collectionKey;
    if(collectionKey){
      this.state.uniqueKey = collectionKey;
    }
    this.getHospices(collectionKey);
  },
  methods: {
    getHospices(collectionKey) {
      let self = this;
      this.$setBusy(true);

      axios
        .get(process.env.VUE_APP_BASE_URL + "/hospices")
        .then(function (response) {
          self.hospiceList = response.data;
          if(collectionKey && self.hospiceList.some(h => h.value === collectionKey)){
            self.isCollectionKeySetInUrl = true;
          }
          self.$setBusy(false);
        })
        .catch(function () {
          self.$setBusy(false);
        });
    },
    submit() {
      this.hasValidationError = false;
      this.emailNotRegistered = false;
      if (this.$v.$anyError) {
        this.$setBusy(false);
        return;
      }

      this.$setBusy(true);
      const { state } = this;
      var vm = this;

      this.$store
        .dispatch(AUTH_REQUEST, { state })
        .then((response) => {
          this.$setBusy(false);
          vm.routeOptions.push(...response.data.availableRoutes);
          vm.state.routeId = response.data.availableRoutes[0].value;
          vm.authData = response.data.code;
          debugger;
        })
        .catch((errorResponse) => {
          this.hasValidationError = true;
          this.emailNotRegistered = errorResponse.response.data.message == 'Email';
          this.$setBusy(false);
        });
    },
    submitLogin() {
      const { authData } = this;
      var routeId = this._data.routeOptions.find(
        (i) => i.value === this._data.state.routeId
      ).text;

      this.$store.dispatch(AUTH_COMMIT, { authData, routeId }).then(() => {
        this.$router.push({
          name: "route-details",
          params: { routeId: routeId },
        });
      });
    },

    getLink(){
      return process.env.VUE_APP_VOLUNTEERREGISTRATION_URL
    }
  },
};
</script>
<style lang="scss">
.login-page {
  min-height: 60vh;
}
</style>
