import Vue from "vue";
import Vuex from "vuex"
import auth from "./modules/auth";
import online from "./modules/online";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        auth,
        online
    },
    strict: debug
});