import Login from '../views/Login.vue'
import RouteItemDetails from "../views/RouteItemDetails";
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../stores"

Vue.use(VueRouter)

const shouldBeAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};

const shouldBeAnonymous = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const routes = [
    {
        path: '/login/:collectionKey?',
        name: 'login',
        component: Login,
        beforeEnter: shouldBeAnonymous
    },
    {
        path: '/route/:routeId',
        name: 'route-details',
        component: () => import('../views/RouteDetails.vue'),
        beforeEnter: shouldBeAuthenticated
    },
    {
        path: '/route/:routeId/item/:itemId',
        name: 'route-item-details',
        component: RouteItemDetails,
        beforeEnter: shouldBeAuthenticated,
    },
    {
        path: '*/',
        redirect: '/login/'
    },
    {
        path: '*/:collectionKey',
        redirect: '/login/:collectionKey'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
