export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_COMMIT = "AUTH_COMMIT";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_REQUEST = "USER_REQUEST";
export const UNLOCK_ROUTE = "UNLOCK_ROUTE";
export const USER_ERROR = "USER_ERROR";

export const APP_CHECK_INTERNET_CONNECTION = "APP_CHECK_INTERNET_CONNECTION";
export const APP_INTERNET_CONNECTION_SUCCESS = "APP_INTERNET_CONNECTION_SUCCESS";
export const APP_INTERNET_CONNECTION_FAILED = "APP_INTERNET_CONNECTION_FAILED";