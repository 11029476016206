<template>
  <div id="app" class="mobile-app">
    <busy-indicator></busy-indicator>
    <div class="mobile-app__header">
      <div
        v-if="$store.getters.isOnline"
        class="divider background--pepper"
      ></div>
      <div v-if="!$store.getters.isOnline" class="divider background--error">
        <p>You are offline</p>
      </div>
      <b-container>
        <b-row class="header-content color--white">
          <b-col cols="12" class="text-center pt-3">
            <b-img
              :src="require('./assets/logo.png')"
              class="header-content__logo"
            ></b-img>
          </b-col>
        </b-row>
        <b-row v-if="$store.getters.isAuthenticated">
          <b-col cols="12" class="text-center pt-3">
            <b-link href="https://www.youtube.com/watch?v=wAjeGbsBbfw" 
                    target="_blank">
                    Welcome to Just Helping Christmas Tree Recycling App 'click on the link to view the tutorial'
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="mobile-app__content">
      <router-view />
    </div>
  </div>
</template>
<script>
import busyIndicator from "./components/BusyIndicator";
import * as VueGoogleMaps from "vue2-google-maps";
import { APP_CHECK_INTERNET_CONNECTION } from "./stores/modules/constants";

export default {
  components: {
    busyIndicator,
  },
  beforeMount() {
    VueGoogleMaps.loadGmapApi({
      key: this.gmapKey,
    });
  },
  created() {
    setInterval(() => {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(APP_CHECK_INTERNET_CONNECTION);
      }
    }, 10000);
  },
  computed: {
    gmapKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_KEY;
    },
  },
};
</script>
<style lang="scss">
@import "./scss/_variables";

.mobile-app {
  &__content {
    margin-top: 32px;
  }

  .header-content {
    &__logo {
      width: 300px;
    }
  }
}
</style>
