var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "route-map" },
    [
      _c(
        "GmapMap",
        {
          staticStyle: { width: "100%", height: "600px" },
          attrs: {
            center: _vm.coordinates,
            zoom: _vm.zoom,
            "map-type-id": "roadmap",
          },
        },
        [
          _c(
            "gmap-info-window",
            {
              attrs: {
                options: _vm.infoOptions,
                position: _vm.coordinates,
                opened: _vm.infoWinOpen,
              },
              on: {
                closeclick: function ($event) {
                  _vm.infoWinOpen = false
                },
              },
            },
            [_vm._t("default")],
            2
          ),
          _c("GmapMarker", {
            attrs: {
              position: _vm.coordinates,
              clickable: true,
              draggable: true,
            },
            on: {
              click: function ($event) {
                return _vm.openInfoWindow()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }